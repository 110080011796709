document.addEventListener('change', function(event) {
  if (event.target.id === 'file_type') {
    xhr.send('file_type=' + event.target.options[event.target.selectedIndex].value);
  } else if (event.target.id === 'file_seller_type_id') {
    var xhr = new XMLHttpRequest();
    xhr.open('POST', '/downloads/reload_sellers_search_form');
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    xhr.onload = function() {
      if (xhr.status === 200) {
        eval(xhr.responseText);
      }
    };
    xhr.send('seller_type_id=' + event.target.options[event.target.selectedIndex].value);
  } else if (event.target.id === 'file_seller_id') {
    var xhr = new XMLHttpRequest();
    xhr.open('POST', '/downloads/reload_rankings_search_form');
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    xhr.onload = function() {
      if (xhr.status === 200) {
        eval(xhr.responseText);
      }
    };
    xhr.send('seller_type_id=' + event.target.options[event.target.selectedIndex].value);
  }
});