["turbo:load", "turbo:frame-load"].forEach(function (evt) {
  document.addEventListener(evt, function () {
    document.addEventListener("hidden.bs.modal", function (e) {
      CleanModalContent("modal-window-sm");
      CleanModalContent("modal-window-md");
      CleanModalContent("modal-window-lg");
      CleanModalContent("modal-window-xl");
    });
  });
});


function CleanModalContent(modalId) {
  var modal = document.getElementById(modalId);
  if (modal) {
    var modalContent = modal.querySelector(".modal-content#content");
    var modalContentLoading = modal.querySelector(".modal-content#loading");
    if (modalContent && modalContentLoading) {
      modalContentLoading.classList.remove("d-none");
      modalContentLoading.classList.add("d-block");
      modalContent.innerHTML = "";
      modalContent.classList.remove("d-block");
      modalContent.classList.add("d-none");
    }
  }
}

function UpdateModalContent(modalId, html) {
  var modal = document.getElementById(modalId);
  if (modal) {
    var modalContent = modal.querySelector(".modal-content#content");
    var modalContentLoading = modal.querySelector(".modal-content#loading");
    if (modalContent && modalContentLoading) {
      modalContent.innerHTML = html || "";
      modalContentLoading.classList.remove("d-block");
      modalContentLoading.classList.add("d-none");
      modalContent.classList.remove("d-none");
      modalContent.classList.add("d-block");
    }
  }
}

export { UpdateModalContent };