document.addEventListener('DOMContentLoaded', function() {
  var searchProductByCategoryId = document.querySelector('#search_product_by_category_id');
  if (searchProductByCategoryId) {
    searchProductByCategoryId.addEventListener('change', function() {
      var xhr = new XMLHttpRequest();
      xhr.open('POST', '/products/reload_subcategories_search_form');
      xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      xhr.onload = function() {
        if (xhr.status === 200) {
          eval(xhr.responseText);
        }
      };
      xhr.send('category_id=' + this.options[this.selectedIndex].value);
    });
  }
});