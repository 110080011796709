import { checkIsSafari } from "./utils";

// #10-01
export default function initStickyThead() {
  jQuery(".table-wrapper").each(function () {
    const self = jQuery(this);
    const thead = self.find(".table-head");

    if (thead.length === 0) return;

    const header = jQuery(".header.sticky-top");
    const pagingHolder = self.find(".paging-holder.top");
    const table = self.find(".table");
    const tableHolder = self.find(".table-responsive");
    const tHeadTr = thead.find("tr");
    const clonedThead = thead.clone();
    const clonedTr = clonedThead.find("tr");
    const wrapper = jQuery('<div class="thead-wrapper"></div>');

    let headerHeight;
    let pagingHolderHeight;
    let initialOffsetTop;
    let initialMaxHeight;
    let tableWidth;

    const isSafari = checkIsSafari();

    const calcHeights = () => {
      headerHeight = !!header.length ? header.innerHeight() : 0;
      pagingHolderHeight = !!pagingHolder.length
        ? pagingHolder.innerHeight()
        : 0;
      initialOffsetTop = thead.offset().top - headerHeight - pagingHolderHeight;
      initialMaxHeight =
        tableHolder.offset().top -
        headerHeight -
        pagingHolderHeight +
        tableHolder.innerHeight() -
        thead.innerHeight();
      tableWidth = self.width();
    };

    const handleTableHolderScroll = () => {
      self.find(".thead-wrapper").scrollLeft(tableHolder.scrollLeft());
    };

    const calcCss = () => {
      clonedTr.each(function (idx, el) {
        const th = jQuery(el).find("th");

        th.each(function (thIdx, thEl) {
          const thWidthCss = jQuery(
            jQuery(tHeadTr.get(idx)).find("th").get(thIdx)
          ).css("width");
          const thWidth = parseFloat(thWidthCss);
          jQuery(thEl).css(
            "width",
            (thIdx === 0
              ? thWidth + 1
              : thIdx === 1
                ? thWidth - 0.5
                : thWidth) + "px"
          );
        });
      });

      self.find(".thead-wrapper").css({
        width: tableWidth + "px",
        top: headerHeight + pagingHolderHeight - (isSafari ? 1 : 0) + "px",
        left: self.offset().left - jQuery(window).scrollLeft() + "px",
      });

      clonedThead.css({
        width: table.css("width"),
      });

      handleTableHolderScroll();
    };

    const handleScroll = () => {
      calcHeights();

      if (
        jQuery(window).scrollTop() > initialOffsetTop &&
        jQuery(window).scrollTop() < initialMaxHeight
      ) {
        if (!self.find(".thead-wrapper").length) {
          self.addClass("sticky-thead");
          clonedThead.insertBefore(thead);
          clonedThead.wrap(wrapper);

          calcCss();

          tableHolder.on("scroll", handleTableHolderScroll);
        }
      } else {
        if (self.find(".thead-wrapper").length) {
          self.removeClass("sticky-thead");
          self.find(".thead-wrapper").remove();

          tableHolder.off("scroll", handleTableHolderScroll);
        }
      }
    };

    jQuery(window).on("scroll", handleScroll);
    calcHeights();
    calcCss();

    jQuery(window).on("resize orientationchange scroll", () => {
      calcHeights();
      calcCss();
    });
  });
}
