export default function initListGridView() {
  [...document.querySelectorAll('.card-tab-content')].forEach(tabContent => {
    
    [...tabContent.querySelectorAll('.test')].forEach(tab => {
      const btns = tab.querySelectorAll('.view-group .btn');
      const blocks = tab.querySelectorAll('.list-view, .grid-view');
      const activeClass = 'active';

      btns.forEach(btn => {
        btn.addEventListener('click', (e) => {

          [...btns].forEach(btn => {
            btn.classList.remove(activeClass);
          });
          btn.classList.add(activeClass);

          [...blocks].forEach(block => {
            if (block.classList.contains(btn.dataset.view)) {
              block.style.display = 'block';
            } else {
              block.style.display = 'none';
            }
          });
        });
      });
    });
  });
}