["turbo:load", "turbo:frame-load", "DOMContentLoaded"].forEach(function (evt) {
  document.addEventListener(evt, function () {
    var competitiveMapTabs = document.getElementById("ModalTabCompetitiveMap");
    var csvLink = document.getElementById("export-competitive-map");

    if (competitiveMapTabs) {
      var competitiveMapTabsLinks = competitiveMapTabs.querySelectorAll(
        'button[data-bs-toggle="tab"]'
      );
      competitiveMapTabsLinks.forEach(function (link) {
        link.addEventListener("shown.bs.tab", function (event) {
          var activeTab = event.target;
          var dataHref = activeTab.getAttribute("data-href-csv");
          csvLink.href = dataHref;
        });
      });
    }
  });
});


["turbo:load", "turbo:frame-load", "DOMContentLoaded"].forEach(function (evt) {
  document.addEventListener(evt, function () {
    var elements = document.querySelectorAll("#competitive-map-tab");

    elements.forEach(function (element) {
      element.addEventListener("click", function (event) {
        var activeTab = event.target;
        var csvLink = document.getElementById("export-competitive-map");
        var dataHref = activeTab.getAttribute("data-href-csv");
        csvLink.href = dataHref;
      });
    });
  });
});