document.addEventListener('change', function(event) {
  if (event.target.id === 'search_by_seller_type_id') {
    var xhr = new XMLHttpRequest();
    xhr.open('POST', '/prices/reload_sellers_search_form');
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    xhr.onload = function() {
      if (xhr.status === 200) {
        eval(xhr.responseText);
      }
    };
    xhr.send('seller_type_id=' + event.target.options[event.target.selectedIndex].value);
  } else if (event.target.id === 'check-all') {
    var checkboxes = document.querySelectorAll('.price-check-box');
    for (var i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = event.target.checked;
    }
  }
});
